/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';
import StickyBox from 'react-sticky-box';
// import useDynamicRefs from 'use-dynamic-refs';

/* Internal Imports */
/* Components */
import { RenderSections } from '@components/UI/Common/SectionCommonUI';
import useDynamicRefs from '@hooks/useDynamicRefs';
import { LangStyle } from 'constants/index';

/* Services */
// import { consoleLog } from '@components/Utility/Shared/SharedService';

/**
 * Renders the Left Nav CommonUI component.
 *
 * @param {Array} pageBody - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param {boolean} isLeftMenu - Check if this component is used in left menu pages.
 * @returns {ReactElement} Left Nav Common UI component.
 */

const LeftNavCommonUI = ({
  pageBody,
  region,
  lang,
  isLeftMenu,
  csrData,
  fullWidth,
}) => {
  return (
    <Box as="section" className="container-article btn-center">
      {pageBody && pageBody?.length > 0 && (
        <ParseBody
          pageBody={pageBody}
          region={region}
          lang={lang}
          isLeftMenu={isLeftMenu}
          csrData={csrData}
          fullWidth={fullWidth}
        />
      )}
    </Box>
  );
};

const ParseBody = ({
  pageBody,
  region,
  lang,
  isLeftMenu,
  csrData,
  fullWidth,
}) => {
  const router = useRouter();
  const routerHashPath = router?.asPath.split('#')[1];
  const [getRef, setRef] = useDynamicRefs();
  const isMobile = useMediaQuery({ maxWidth: 1025 });

  // const isMobile = useMediaQuery('(max-width: 1025px)');
  // consoleLog('useMediaQuery', useMediaQuery, isMobile);
  // const { height, width } = useWindowDimensions();
  // consoleLog({ height, width });

  let currentSelectedMenuId = '';
  const scrollToCategory = id => {
    isMobile && getRef(id)?.current?.scrollIntoView({ inline: 'center' });
  };

  useEffect(() => {
    if (routerHashPath) {
      if (isMobile) {
        setTimeout(() => {
          while (router?.asPath.split('#')[1] !== currentSelectedMenuId) {
            scrollToCategory(routerHashPath);
            console.log(
              'useMediaQuery',
              router?.asPath.split('#')[1],
              currentSelectedMenuId
            );
            currentSelectedMenuId = router?.asPath.split('#')[1];
          }
        }, 4000);
      }

      setTimeout(() => {
        // consoleLog('inside setTimeout', routerHashPath);
        const element = document.getElementById(routerHashPath);
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }, 5000);
    }
  }, [router.asPath]);

  return (
    <Box
      display={{
        base: 'block',
        lg: 'flex',
      }}
      alignItems={['center', 'center', 'center', 'flex-start']}
    >
      <StickyBox style={{ zIndex: 12 }}>
        <Flex
          w={{
            base: '100%',
            lg: '270px',
          }}
          minH={{ lg: '800px', base: '1px' }}
          h={{ base: '100%', lg: 'auto' }}
          display={{
            base: 'inline-block',
            lg: 'block',
          }}
          bgColor="#f5f0e5"
          zIndex={888}
        >
          <Box
            as="ul"
            w={{
              base: '100%',
              lg: '200px',
            }}
            ml="auto"
            textAlign={['start', 'start', 'end', 'end']}
            pt={{ base: 0, xl: '20px' }}
            h={{ base: '100%', lg: '100vh' }}
            mt={0}
            display={{
              base: 'flex',
              lg: 'block',
            }}
            flexDirection={{
              base: 'row',
              lg: 'column',
            }}
            overflowY={{
              base: 'hidden',
              md: 'inherit',
            }}
            overflowX={{ sm: 'scroll', lg: 'visible' }}
            whiteSpace={{ base: 'nowrap', lg: 'normal' }}
            listStyleType="none"
            pl={['20px', '20px', '20px', 0]}
            // flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
            height={['55px', '55px', '55px', '100%']}
            justifyItems={['center', 'center', 'center', 'flex-start']}
            alignItems={['center', 'center', 'flex-start', 'flex-start']}
            bgColor={'#e4ded4'}
            pb={{ lg: '100px', base: '0' }}
            borderBottom={{ base: 'solid 1px #dedede', md: 'none' }}
          >
            {pageBody && pageBody.length
              ? pageBody.map((section, i) => {
                let to = section.sectionId
                  .replace(/ /g, '-')
                  .toLowerCase()
                  .toString();
                // consoleLog(section.menuLabel, section.sectionId);
                return (
                  (section.menuLabel || section.sectionId) && (
                    <Box key={i} ref={setRef(to)} as="li">
                      <Link
                        activeClass="activeNav"
                        spy
                        hashSpy
                        smooth
                        duration={40}
                        offset={isMobile ? -50 : 0}
                        to={to}
                        // saveHashHistory={false}
                        onClick={() => scrollToCategory(to)}
                        href={`#${to}`}
                      >
                        <Box
                          p={{ base: '15px 10px', lg: '15px 20px 15px 10px' }}
                          fontFamily={{
                            base: LangStyle[lang]?.fontFamily
                              ? LangStyle[lang].fontFamily
                              : 'FedraSansStd-book',
                            lg: LangStyle[lang]?.fontFamily
                              ? LangStyle[lang].fontFamily
                              : 'FedraSansStd-medium',
                          }}
                          fontWeight={LangStyle[lang]?.fontWeight}
                          fontSize={{ base: '14px', md: '16px' }}
                          display="block"
                          textTransform="capitalize"
                          color={'#28231e'}
                        // borderLeft="10px solid #d33100"
                        // ml="-9px"
                        >
                          {section.menuLabel ||
                            section.sectionId.replace(/-/g, ' ')}
                        </Box>
                      </Link>
                    </Box>
                  )
                );
              })
              : null}
          </Box>
          <style global jsx>
            {`
              .activeNav {
                background: transparent;
                border-bottom: 2px solid #d33100;
                display: inline-block;
                margin-left: -9px;
              }
              @media (min-width: 992px) {
                .activeNav {
                  background: white;
                  border-left: 10px solid #d33100;
                  border-bottom: none;
                  display: block;
                  margin-left: -9px;
                }
              }
              @media screen and (max-width: 768px) {
                .activeNav {
                  background: transparent;
                  border-left: 0;
                  color: #28231e;
                  border-bottom: 3px solid #d33100;
                  display: inline-block;
                }
                .activeNav div {
                  color: #28231e;
                }
              }
              @media screen and (max-width: 480px) {
                .activeNav {
                  background: transparent;
                  border-left: 0;
                  color: #28231e;
                  border-bottom: 3px solid #d33100;
                  display: block;
                }
                .activeNav div {
                  color: #28231e;
                }
              }
            `}
          </style>
        </Flex>
      </StickyBox>
      <Box
        w={{
          base: '100%',
          sm: '100%',
          md: '100%',
          xl: '988px',
          // xl: fullWidth && (fullWidth - 270) || '1024px',
        }}
      // maxWidth={{
      //   base: '100%',
      //   lg: width - 270,
      // }}
      >
        <Box
          width={{
            base: '100%',
            sm: '100%',
            md: '100%',
          }}
          mx="auto"
          px={0}
        >
          {pageBody && pageBody.length
            ? pageBody.map((section, i) => {
              return (
                <Box
                  as="div"
                  key={i}
                  id={section.sectionId.replace(/ /g, '-').toLowerCase()}
                  ref={setRef(
                    `section-${section.sectionId
                      .replace(/ /g, '-')
                      .toLowerCase()}`
                  )}
                  width="100%"
                  float={{ base: 'unset', lg: 'left' }}
                >
                  <RenderSections
                    section={section}
                    region={region}
                    lang={lang}
                    isLeftMenu={isLeftMenu}
                    csrData={csrData}
                  />
                </Box>
              );
            })
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default LeftNavCommonUI;
